import { SEO } from "components/seo/SEO.component";
import { useRelatedPages } from "hooks/relatedPages.hook";
import React from "react";
import linksService from "services/links.service";
import { RELATED_PAGES_LABELS } from "components/constants";

const InquiryForm = () => {
  useRelatedPages([
    { label: RELATED_PAGES_LABELS.HOME, link: linksService.home() },
    { label: RELATED_PAGES_LABELS.OUR_STORY, link: linksService.ourStory() },
    {
      label: RELATED_PAGES_LABELS.HOW_TO_APPLY,
      link: linksService.howToApply(),
    },
  ]);

  return (
    <>
      <SEO title="Inquiry Form" />
      <div className="fix-header-overlap-without-padding inquiry-form-wrapper">
        <iframe
          className="inquiry-form-iframe"
          frameborder="0"
          scrolling="no"
          src="https://mypacbay.myschoolapp.com/app/embed#inquiry/83046"
          width="100%"
        />
      </div>
    </>
  );
};

export default InquiryForm;

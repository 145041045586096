import { Context } from "contexts/Context.store";
import { useWindowWidth } from "hooks/windowWidth.hook";
import { useContext, useEffect } from "react";

export const useRelatedPages = (pages) => {
  const { setRelatedPages } = useContext(Context);
  const windowWidth = useWindowWidth();

  useEffect(() => {
    if (windowWidth !== 0) {
      const massagedPages = windowWidth <= 560 ? pages.slice(0, 2) : pages;
      setRelatedPages(massagedPages);
    }

    return () => {
      setRelatedPages([]);
    };
  }, [windowWidth]);
};
